<template>

  <section id="inscripciones">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
          <i class="fa-solid fa-bus-simple color-secondary"></i>
          En esta seccion podrás solicitar o cancelar solicitudes para tus colaboradores.
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <router-view />
      </div>
    </div>
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="acceptStatus"
    />
  </section>

</template>

<script>
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";

export default {
  components: {
    Question,
    Status,
  },

  data() {
    return {
      showComponent: "Search",
      open_question_modal: false,
      open_modal_status: false,
    };
  },


  // Solución temporal al bug de los asientos: recargar la página
/*   watch: {
    '$route' (_, from) {
      if (["add", "edit"].includes(from.params.action)) {
        window.location.reload();
      } 
    }
  },  */
  
  methods: {
    addUser() {
      this.showComponent = "Add";
    },
    editUser() {
      this.showComponent = "Edit";
    },
    listUser() {
      this.showComponent = "Search";
    },
    pushTransporte() {
      this.open_question_modal = true;
      this.question_modal_msg = "¿Está seguro de ...?";
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    acceptQuestion() {
      this.open_modal_status = true;
      this.modal_status = true;
      this.open_question_modal = false;
      this.modal_status_msg = "";
      this.showComponent = "Search";
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },
  },
};
</script>